import React from 'react'
import usePageTitle from '../stores/pageTitle'
import { useRouter } from 'next/router'
import useCheckInvitation from '../libs/checkInvitation'

export default function Index(): JSX.Element {
  const router = useRouter()
  usePageTitle('Home')
  // 特に表示するものがないので一旦清掃表にリダイレクト
  const { data } = useCheckInvitation()
  if (!data || data.data.length === 0) {
    router.push('/cleaning_table')
  }

  return <></>
}
